.backend
{
	#mainContentStart
	{
		>.container-fluid
		{
			max-width: 96%;
		}
	}
	h1, h2
	{
		color:$black;
	}
	form.backend-form
	{
		$form-error: $red;
		$secondary: $navy;
	    .error-message
	    {
	        display: none;
	    }
	    h4
	    {
	    	margin:2rem 0 1rem 0;
	    }
	    label
	    {
	        width:100%;
	        margin: 1rem 0 5px 0;
	        padding: 0px;
	        display: block;
	        font-weight: $font_weight_bold;
	    }
	    .description
	    {
	        width:100%;
	        margin: 0 0 10px 0;
	        padding: 0px;
	        display: block;
	        font-size: 0.8rem;
	        color:$grey;
	    }

	    p.default-value
	    {
	    	font-size: 11px;
	    	position: relative;
	    	border:1px solid $grey;
	    	padding: 8px;
	    	margin-top: -10px;
	    	&:before
	    	{
	    		content: "API Value: ";
	    		display: inline-block;
	    		font-weight: bold;
	    		padding-right: 10px;
	    	}
	    }

	    input[type="checkbox"], input[type="radio"]
	    {
	        accent-color: $secondary;
	    }

	    input[type="text"], input[type="password"], input[type="number"], input[type="date"], input[type="email"],  input[type="url"],  textarea, select 
	    {
	        width:100%;
	        display: block;
	        font-size: 1.0rem;
	        border-radius: 3px;
	        background: #fafafa;
	        padding:12px 20px;
	        font-size: 1.0rem;
	        border:1px solid #ccc;
	        margin: 0 0 1rem 0;

	        &.is-required
	        {
	            &::placeholder
	            {
	                //color:red!important;
	            }
	        }

	        &:focus
	        {
	            border: 1px solid #000;
	            background: white;
	        }
	    }
	    textarea
	    {
	        height:120px;
	    }
	    select, select:focus
	    {
	        appearance: none;
	        padding:12px 60px 12px 20px!important;
	        color:$black!important;

	        background-image:
	            linear-gradient(45deg, transparent 50%, gray 50%),
	            linear-gradient(135deg, gray 50%, transparent 50%),
	            linear-gradient(to right, #ccc, #ccc);
	        background-position:
	            calc(100% - 20px) calc(1em + 6px),
	            calc(100% - 15px) calc(1em + 6px),
	            calc(100% - 2.5em) 12px;
	        background-size:
	            5px 5px,
	            5px 5px,
	            1px 1.5em;
	        background-repeat: no-repeat;
	        option
	        {
	            line-height: 1.5!important;
	        }

	    }

	    fieldset
	    {

	        display: block;
	        margin: 1.0rem 0 1rem 0;
	        legend
	        {
	            font-size: 1.0rem;
	            font-weight: $font_weight_bold;
	        }

	    }

	    fieldset.standard-form
	    {   
	        label
	        {
	            font-weight: normal!important;
	            margin: 0.5rem 3rem 1rem 0rem;
	            padding-left: 2.25rem;
	            position: relative;
	            display: inline-block;
	            width: auto;
	            input[type="checkbox"], input[type="radio"]
	            {
	                height: 1.5rem;
	                width: 1.5rem;
	                position: absolute;
	                left:0px;
	                top:0rem;
	            }
	        }
	    }
	    fieldset.radio-as-buttons
	    {
	        input 
	        {
	            display: none !important;
	            &:checked + label 
	            {
	                background-color: $primary;
	                color: #fff;
	                box-shadow: none;
	            }
	        }

	        label 
	        {
	            float: left;
	            width: auto!important;
	            background-color: $white;
	            border: 1px solid $primary;
	            color: $primary;
	            text-align: center;
	            text-shadow: none;
	            margin: 0.5rem 1.5rem 0.5rem 0;
	            padding: 6px 16px;
	            transition: all 0.2s ease-in-out;
	            font-size:1.0rem;
	            border-radius:3px;
	            font-weight: $font_weight_regular;
	            &:hover 
	            {
	                cursor: pointer;
	                cursor:hand;
	                background: $grey;
	            }
	        }
	    }
	    fieldset.boolean-checkbox
	    {

	        label 
	        {
	            display: inline-block;
	            height: 2rem;
	            position: relative;
	            width: 4rem;

	            input
	            {
	                display:none;
	            }

	            .slider 
	            {
	                background-color: #ccc;
	                bottom: 0;
	                cursor: pointer;
	                left: 0;
	                position: absolute;
	                right: 0;
	                top: 0;
	                transition: .4s;
	                border-radius: 10px;
	                &:before
	                {
	                    background-color: #fff;
	                    bottom: 0.4rem;
	                    content: "";
	                    height: 1.2rem;
	                    right: 0.4rem;
	                    position: absolute;
	                    transition: .4s;
	                    width: 1.2rem;   
	                    border-radius: 50%;
	                }
	            }

	            input:checked + .slider 
	            {
	                background-color: #66bb6a;
	            }

	            input:checked + .slider:before 
	            {
	                transform: translateX(-2rem);
	            }

	        }

	        &.inline-legend
	        {
	            legend
	            {
	                display: inline-block;
	                max-width: 60%;
	                width: auto;
	                float: left;
	                margin-right: 2rem;
	            }
	            label
	            {
	                margin-top: 0px;
	            }
	        }

	    }


	    .has-required
	    {
	        label
	        {
	            &:after
	            {
	                content:" *";
	                color: $form_error;          
	            }
	        }
	    }

	    .hide-label
	    {
	        input[type="text"], input[type="password"], input[type="number"], input[type="date"], input[type="email"], input[type="url"],  textarea, select 
	        {
	            width: 100%;
	            clear:both;
	            margin-top: 1rem;
	        }

	        label
	        {
	            //@include sr-visible-only;
	        }

	    }


	    .has-error
	    {
	        input[type="text"], input[type="password"], input[type="number"], input[type="date"], input[type="email"], input[type="url"], textarea, select 
	        {
	            border:1px solid $form_error;
	        }   
	        >label, legend
	        {
	            color:$form_error;
	        }
	        .error-message
	        {
	            display: block;
	            clear: both;
	            margin:0px 0px 10px 0px;
	            padding: 4px 16px;
	            font-size: 0.8rem;
	            border-left: 5px solid $form_error;
	            &.error-welcome
	            {
	                background: rgba($form_error, 0.2);
	                font-size: 1.1rem;
	                padding:1.5rem;
	            }
	        }
	    }
	    .is-error + .error-message
	    {
	        display: block;
	        clear: both;
	        margin:5px 0px 10px 0px;
	        padding: 4px 16px;
	        font-size: 0.8rem;
	        border-left: 5px solid $form_error;
	    }

	    .break-labels
	    {
	        label
	        {
	            display: block;
	            margin:10px 0px 5px 0px;
	            float:none;
	            clear: both;
	        }
	        input[type="text"], input[type="password"], input[type="number"], input[type="date"], input[type="email"], input[type="url"], textarea, select 
	        {
	            clear:both;   
	            float: none;
	        }

	    }
	}
}