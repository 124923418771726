body[data-region-id="region-ni"]  {
	div.width-wrapper {
		background-color: $ni_green;
	}
	h1, h2
	{
		font-family: $eags_font;
		font-weight: normal;
	}
	h2
	{
		margin-bottom: 2rem;
	}
	.area-3
	{
		.slideshow-area
		{
			background-color: $navy;
			background-image: url($media_path + 'ni/coloured-strip.png');
			background-size: 100%;
		}
	}
}

body[data-theme-id="region-ni"]  {

	#pageRegion {

		header {
			position: relative;
		}

		.region-background-container {
			position: absolute;
			width: 100%;
			height: 100%;
			overflow: hidden;
		}

		header img
		{
			width: 81%;
			height: auto;
			position: absolute;
			top:50px;
			left:50%;
			transform: translateX(-50%);

		}


		.on-ni
		{
			margin-top: -864px;
			height: 100%;
			.featured
			{
				height: 100%;

				.inner-relative
				{
					.button-container {
						top:50%;
						left:50%;
						transform: translate(-50%, -50%);
						width: 100%;
						aspect-ratio : 1 / 1;
						position: relative;
						//background-color: red;

						.featured-button
						{
							aspect-ratio : 1 / 1;
							transform: translate(-50%, -50%);
							>div
							{
								overflow: visible;
							}
							.inner{
								position: relative;
								overflow: visible;
								width: 100%;
								height: 100%;
								.bg {
									content: ' ';
									position: absolute;
									display: inline;
									transform: translate(-50%, -50%);
									left: 50%;
									top:50%;
									width:100%;
									height: 100%;
									background: transparent;
									border-radius: 50%;
								}
								.img-mask {
									height: 100%;
									width:100%;
									border-radius: 50%;
									overflow: hidden;
									position: absolute;
									transform: translate(-50%, -50%);
									left: 50%;
									top:50%;
								}
								img {
									transform: translate(-50%, -50%);
									top: 50%;
									left: 50%;
								}
								.button {
									transform: translate(-50%, -50%);
									top: 50%;
									left: 50%;
								}
							}

							&.derry-londonderry {
								left: 42%;
								top: 18%;
								width: 47%;
								.inner
								{
									.bg {
										background: $derry_purple;
									}
									img {
										height: 101%;
										width: auto;
										transform: translate(-56%, -50%);
									}
									.button {
										left: 62%;
										top: 95%;

										&.dni
										{
											background: $derry_purple;
											&:hover
											{
            									background: white;
												color:$derry_purple;
											}
										}
									}
								}
								&.is-hovered, &.external-hover  {

									.button {

										&.dni
										{
											background: white;
											color:$derry_purple;
										}
									}
								}
							}

							&.causeway-coastal-route {
								left: 82%;
								top: 28%;
								width: 38%;
								.inner
								{
									.bg {
										background: $causeway_blue_2;
									}
									img {
										height: auto;
										width: 100%;
										transform: translate(-50%, -50%);
									}
									.button {
										left: 37%;
										top: 92%;
									}
								}
							}

							&.down{
								left: 80%;
								top: 69%;
								width: 39%;
								.inner
								{
									.bg {
										background: $down_green;
									}
									img {
										height: 110%;
										width: auto;
										transform: translate(-50%, -54%);
									}
									.button {
										left: 5%;
										top: 55%;
									}
								}
							}

							&.armagh {
								left: 56%;
								top: 96%;
								width: 38%;
								.inner
								{
									.bg {
										background: $armagh_yellow;
									}
									img {
										height: auto;
										width: 101%;
										transform: translate(-50%, -50%);
									}
									.button {
										left: 84%;
										top: 84%;
									}
								}
							}

							&.tyrone {
								left: 15%;
								top: 47%;
								width: 45%;
								.inner
								{
									.bg {
										background: $tyrone_navy;
									}
									img {
										height: auto;
										width: 103%;
										transform: translate(-50%, -57%);
									}
									.button {
										left: 96%;
										top: 68%;
									}
								}
							}

							&.fermanagh {
								left: 22%;
								top: 83%;
								width: 38%;
								.inner
								{
									.bg {
										background: $fermanagh_blue;
									}
									img {
										height: 101%;
										width: auto;
										transform: translate(-52%, -50%);
									}
									.button {
										left: 75%;
										top: 23%;
									}
								}
							}

						}
					}

				}

			}

		}
	}
}

