body[data-theme-id="area-armagh"] {
	div.width-wrapper {
		background-color: $titanic_yellow;
	}

	#pageArea {
		.area-1
		{
			div.pattern
			{
				background-color: $titanic_yellow;
				background-size: 100%;
				opacity: 1;
			}
		}
	}
}