div.quote-container
{
    position: relative;
    margin:$area_margin 0;
    &:before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 100%;
        background: $text_medium_grey;
        position: absolute;
    }

    div.quote
    {
        padding: 1rem $area_margin;
        h3
        {
            margin-bottom: 1rem;
            color: $text_medium_grey;
        }

        .quote-content
        {
            color: $text_grey;
            line-height: 1.2;
        }

        div.quote-source
        {

            font-weight: bold;
            font-size: 0.9em;
            margin-top: 1rem;
            margin-bottom: 0;
            padding-bottom: 0;
            color: $text_medium_grey;
        }
    }

}