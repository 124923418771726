body[data-theme-id="area-eastside"] {
	div.width-wrapper {
		background-color: $eastside_orange;
	}

	#pageArea {
		header
		{
			.area-1
			{
				div.backdrop-1
				{
					opacity: 1;
					width: 100%;
					margin-top: -10%;
					height: auto;
					position: absolute;
				}
				div.backdrop-2
				{

				}

				div.pattern
				{
					background-color: $eastside_orange;
					background-size: 80%;
					opacity: 0.6;
				}

				div.featured
				{
					.featured-button[data-product-id="aunt_sandras_candy_factory"] {
						.bubble {
							z-index: -1;
							width: 220%;
							aspect-ratio: 1 / 1;
							transform: translate(-50%, -50%);
							left: 20%;
							top: 50%;
							border-radius: 50%;
							background-color: #bd115e;
							opacity: 0.8;
							position: absolute;
						}
						.button {
							width: 120%;
						}
					}
				}
			}
		}
		main
		{
			.area-3
			{
				.slideshow-area
				{
					background: $eastside_orange;
				}
			}
		}
	}
}