#pageBrowse, #pageRegion {
    header .featured, main .featured
    {
        .inner-relative
        {
            .featured-button
            {
                .inner {
                    overflow: hidden;
                    img
                    {
                        max-width: unset;
                        max-height: unset;
                        position: relative;
                    }
                }
            }
        }
    }
}

#pageBrowse, #pageArea {

    header
    {
        overflow: hidden;
    }
    header .featured, main .featured
    {
        .inner-relative
        {
            .featured-button, .featured-image
            {
                bottom: 0;

                .inner
                {
                    img
                    {
                        max-width: 100%;
                        max-height: 100%;
                        height: auto;
                        width: 100%;
                    }
                }
            }

            .featured-button
            {
                width:auto;
            }
        }
    }
}

#pageBrowse,#pageRegion, #pageArea {
    header
    {
        //height: 45vh; //960px
        //min-height: $header_height;
        height: $header_height;
        width: 100%;
        //background: white;
        .area-1
        {
            position:relative;
            height:100%;
            width:100%;

            .backdrop-1
            {
                overflow: hidden;
                z-index: 1;
            }
            .backdrop-2
            {
                z-index: 2;
            }
            .pattern
            {
                position: absolute;
                width: 100%;
                height:100%;
                top:0px;
                left:0px;
                background-repeat: repeat;
                z-index: 3;
            }

            h1
            {
                position: absolute;
                top: calc( #{$area_margin} * 1.5 );
                left:$area_margin;
                z-index: 15;
            }
            .back-button
            {
                position: absolute;
                top: 50%;
                left:$area_margin;
                transform: translateY(-50%);
                z-index: 16;
            }
        }
    }

    header .featured, main .featured {
        z-index: 4;
        position: absolute;
        bottom: 0px;
        width: 100%;
        left: 0px;
        height: 65%;
    }
    header .featured, main .featured, #locationNav
    {
        .inner-relative
        {
            position: relative;
            height: 100%;
            width: 100%;

            .featured-button, .featured-image, .featured-layer {
                position: absolute;
                @for $i from 1 through 10 {
                    &:nth-child( #{$i} ) {
                        z-index: calc(#{$i} + 4); // 4 is the parent z-index
                    }
                }
            }
            .featured-button, .featured-image, .featured-layer, .featured-button .secondary-container img, .map-area-name
            {

                @for $i from 0 through 125
                {

                    @if $i % 5 == 0
                    {
                        &.wd-#{$i}
                        {
                            width: $i * 1%;
                        }
                        &.ht-#{$i}
                        {
                            height: $i * 1%;
                        }
                        &.from-left-#{$i}
                        {
                            right:auto;
                            left: $i * 1%;
                        }
                        &.from-right-#{$i}
                        {
                            left:auto;
                            right: $i * 1%;
                        }
                        &.from-top-#{$i}
                        {
                            bottom:auto;
                            top: $i * 1%;
                        }
                        &.from-bottom-#{$i}
                        {
                            top:auto;
                            bottom: $i * 1%;
                            img
                            {
                                bottom: 0;
                                vertical-align: bottom;
                            }
                        }

                    }
                }
                &.wd-22-5
                {
                    width:22.5%;
                }
                &.wd-27-5
                {
                    width:27.5%;
                }
                &.from-left-27-5
                {
                    right:auto;
                    left:27.5%;
                }
                &.push-right-5
                {
                    margin-right: -5%;
                }
                &.push-right-2
                {
                    margin-right: -2%;
                }

                &.push-right-10
                {
                    margin-right: -10%;
                }

                &.push-right-15
                {
                    margin-right: -15%;
                }


                &.pull-left-5
                {
                    margin-left: -5%;
                }
                &.pull-left-10
                {
                    margin-left: -10%;
                }

                &.pull-top-5
                {
                    margin-top: -5%;
                }
                &.pull-top-10
                {
                    margin-top: -10%;
                }
                &.pull-top-15
                {
                    margin-top: -15%;
                }
                &.push-bottom-2
                {
                    margin-bottom: -2%;
                }
                &.push-bottom-5
                {
                    margin-bottom: -5%;
                }
                &.push-bottom-10
                {
                    margin-bottom: -10%;
                }
                &.push-bottom-15
                {
                    margin-bottom: -15%;
                }
                &.push-bottom-20
                {
                    margin-bottom: -25%;
                }
                &.push-bottom-25
                {
                    margin-bottom: -25%;
                }
            }

            .featured-button, .featured-image
            {
                .secondary-container {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    img
                    {
                        position: absolute;
                    }
                }
                .inner {
                    position: relative;
                    max-width: 100%;
                    max-height: 100%;

                    img
                    {
                        display: block;
                        overflow: hidden;

                    }
                }


            }
            .featured-button
            {
                 -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                 -khtml-user-select: none; /* Konqueror HTML */
                   -moz-user-select: none; /* Old versions of Firefox */
                    -ms-user-select: none; /* Internet Explorer/Edge */
                        user-select: none;
                &.is-hovered, &.simulate-hovered {
                    cursor: pointer;
                }
                &.is-focused {
                }
                &.is-hovered, &.external-hover  {
                    .button {
                        background: $white;
                        color: $black;
                    }
                }
                .inner
                {
                    .bg {
                        width: 100%;
                        height: 100%;
                    }
                    img {

                    }
                    button, .button
                    {
                        vertical-align: middle;
                        position: absolute;
                        text-align: center;
                        width:92%;
                        max-width:220px;
                        padding: 0.5rem 1.5rem;
                        z-index: 25; // must be clickable
                        transition: .2s ease-in-out;

                        &.no-transition {
                          -webkit-transition: none !important;
                          -moz-transition: none !important;
                          -o-transition: none !important;
                          transition: none !important;
                        }

                        @for $i from 0 through 125
                        {

                            @if $i % 5 == 0
                            {

                                &.from-left-#{$i}
                                {
                                    right:auto;
                                    left: $i * 1%;
                                }
                                &.from-right-#{$i}
                                {
                                    left:auto;
                                    right: $i * 1%;
                                }
                                &.from-top-#{$i}
                                {
                                    bottom:auto;
                                    top: $i * 1%;
                                }
                                &.from-bottom-#{$i}
                                {
                                    top:auto;
                                    bottom: $i * 1%;
                                }
                                &.from-right-minus-10
                                {
                                    left:auto;
                                    right:0;
                                    margin-right: -10%;
                                }
                            }
                        }
                        &.push-bottom-5
                        {
                            margin-bottom: -5%;
                        }
                        &.push-bottom-10
                        {
                            margin-bottom: -10%;
                        }
                        &.pull-left-20
                        {
                            margin-left: -20%;
                        }
                        &.push-right-5
                        {
                            margin-right: -5%;
                        }
                        &.push-right-10
                        {
                            margin-right: -10%;
                        }
                        &.push-right-15
                        {
                            margin-right: -15%;
                        }
                        &.push-right-20
                        {
                            margin-right: -20%;
                        }

                        &.middle
                        {
                            top:50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }


                    }
                }
            }
            .featured-image
            {
                pointer-events: none;
                img {
                    pointer-events: none;
                }
            }

        }
        &.no-buttons
        {
            button, .button, .btn
            {
                display: none;
            }
        }
    }

}

header
{
    position: relative;
    .video-overlay
    {
        z-index: 10000;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        video
        {
            width: calc(100% - 2rem);
            transform: translate(-50%, -50%);
            position: relative;

        }
        .video-controls {
            transform: translate(-50%, -50%);
            width: calc(100% - 2rem);
            height: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            .video-controls-rel
            {
                width: 100%;
                height: 100%;
                position: relative;
                .control-icons {
                    display: inline-block;
                    position: absolute;
                    top: 1rem;
                    right: 1rem;
                }
                .timeline-bar {
                    pointer-events: none;
                    width: calc(100% - 2rem);
                    height: 2rem;
                    position: absolute;
                    left: 1rem;
                    bottom: 1rem;
                    background-color: rgba(0, 0, 0, 0.2);
                    border-radius: 5rem;
                    overflow: hidden;

                    .timeline-bar-rel {
                        width: 100%;
                        height: 100%;
                        position: relative;

                        .timeline-progress {
                            height: 100%;
                            position: absolute;
                            background-color: rgba(0, 0, 0, 0.4);
                        }
                    }
                }
            }
        }
    }
}