#pageRegion {

    .region-background {
        position:absolute;
        top:0;
        right:0;
        content: ' ';
        width:100%;
        height: 100%;
        background-size: auto 1920px;
        background-repeat: no-repeat;
        background-position: top right;
        opacity: 1;
    }

    header, main, footer
    {
        background: transparent;
    }
}