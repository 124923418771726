body[data-theme-id="area-fermanagh"] {
	div.width-wrapper {
		background-color: $gaeltacht_green;
	}

	#pageArea {
		header
		{
			.area-1
			{
				div.pattern
				{
					background-color: $gaeltacht_green;
					background-size: 100%;
					background-repeat: no-repeat;
					opacity: 1;
				}
			}
		}
	}
}