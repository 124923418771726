body[data-theme-id="area-down"] {
	div.width-wrapper {
		background-color: $navy;
	}

	#pageArea {
		header
		{
			.area-1
			{
				div.backdrop-1
				{
					opacity: 1;
					width: 100%;
					margin-top: -10%;
					height: auto;
					position: absolute;
				}
				div.pattern
				{
					background-color: $navy;
					background-size: 100%;
					opacity: 1;
				}
			}
		}
	}
}