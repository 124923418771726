body[data-theme-id="area-cathedral-quarter"] {
	div.width-wrapper {
		background-color: $cathedral_red;
	}

	#pageArea {
		header
		{
			.area-1
			{
				.backdrop-1
				{
					position: absolute;
					width: 58%;
					height:auto;
					top:0px;
					right:-10%;
					overflow: hidden;
					z-index: 1;
					img
					{
						display: block;
						height: 100%;
						width: auto;
						overflow: hidden;
						opacity: 0.8;
					}
				}
				.backdrop-2
				{
					position: absolute;
					width: 60%;
					height: auto;
					bottom: 0px;
					left: 0px;
					overflow: hidden;
					z-index: 2;
					img
					{
						display: block;
						height:100%;
						width: auto;
						overflow: hidden;
						//opacity: 0.7;
					}
				}

				div.pattern
				{
					background-color: $cathedral_red;
					background-size: 80%;
					opacity: 0.3;
				}

				div.featured
				{
					.featured-button[data-product-id="the_oh_yeah_music_centre"] {
						.rings {
							z-index: -1;
							width: 100%;
							aspect-ratio: 1 / 1;
							transform: translate(-50%, -50%);
							left: 66%;
							top:70%;
							position: absolute;
							.ring {
								aspect-ratio: 1 / 1;
								border-radius: 50%;
								transform: translate(-50%, -50%);
								left: 50%;
								top:50%;
								position: absolute;
								&:nth-child(1), &:nth-child(3) {
									background-color: #bd115e;
								}
								&:nth-child(1) {
									width: 135%;
								}
								&:nth-child(2) {
									background-color: #f27164;
									width: 100%;
								}
								&:nth-child(3) {
									width: 77%;
								}
							}
						}
					}
				}
			}
		}
		main
		{
			.area-3
			{
				.slideshow-area
				{
					background: $cathedral_red;
				}
			}
		}
	}
}