body {
    &:not(.can-show-debug-window) {
        #show-debug-window-button {
            display: none;
        }
    }
    &:not(.show-debug-window) {
        #debug-window {
            display: none;
        }
    }
}


#show-debug-window-button {
    position: absolute;
    width: 10%;
    aspect-ratio: 1/1;
    top: 0;
    left: 0;
    z-index: 10000;
}

#debug-window {
    position: absolute;
    width: 100%;
    height: 18%;
    background-color: rgba(0, 128, 0, 0.18);
    top: 0;
    left: 0;
    z-index: 15000;

    .output {
        display: inline-block;
        width: calc(100% - 60px);
        height: 100%;

        float: left;
        overflow-y: scroll;
        .output-table {
            width: 100%;
            font-size: 26px;

            td {
                padding-left: 4px;
                padding-right: 4px;
            }

            td.counter, td.time {
                width:0.1%;
                white-space: nowrap;
            }

            td.time {
                text-align: right;
            }

        }
    }
    .controls {
        display: inline-block;
        width: 60px;
        float: right;
        .close-btn {
            background-color: white;
            margin: 10px auto;
            cursor: pointer;
            text-align: center;
        }
        .clear-log-btn {
            background-color: white;
            margin: 10px auto;
            cursor: pointer;
            text-align: center;
        }
    }
}