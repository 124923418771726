html
{
	scroll-behavior: smooth;
    height:1920px;
}
body
{
	font-family: $body_font;
    height:1920px;
	margin:0px auto;
    &#device-body {
        background-color: $browse_purple;
        &:not(.is-browser-view) {
            overflow: hidden;
        }
    }
    &:not(#device-body) {
	  background-color: $grey;
      background-image:  repeating-linear-gradient(45deg, $white 25%, transparent 25%, transparent 75%, $white 75%, $white), repeating-linear-gradient(45deg, $white 25%, $grey 25%, $grey 75%, $white 75%, $white);
      background-position: 0 0, 10px 10px;
      background-size: 20px 20px;
    }

    /*
    #body-inner {

        width: 1080px;
        height: 1920px;
        background: $olive;
        margin: 0 auto;
        overflow: auto;
    }*/

}

img
{
  max-width: 100%;
  height: auto;
}

.width-wrapper
{
	max-width: 1080px;
    height:1920px;
	margin: 0px auto;
	position: relative;
	overflow: hidden;
    transition:0.5s;
}

html.flow
{
    height:100%;
    body, .width-wrapper
    {
        height: 100%;
        overflow: auto;
    }
}

a[aria-disabled="true"] {
    //background-color: yellow;
    pointer-events: none;
}


