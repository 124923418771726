table
{
    @for $i from 1 through 20 
    {
        .td-#{$i * 5} 
        {
            width: $i * 5%;
        }
    }

    .align-center
    {
        text-align: center!important;
    }

}

.info-table
{
    border: none;

    tbody, thead
    {
        border:none;
    }

    thead tr, thead tr th
    {
        background-color: $grey;
        color: $text_grey;
        font-weight: bold;
        border:none;
        span
        {
            font-size: 0.6rem;
            display:block;

        }
    }

    tr, th, td
    {
        //vertical-align: top;
        font-size: 0.9rem;
    }
    tbody
    {
        tr, td
        {
            background: white;
            color: $text_grey;
            border:none;
            vertical-align: middle;
        }
        tr:not(:last-child)
        {
            border-bottom: 1px solid $grey;
        }
    }


    thead th 
    {
        position: relative;
        background-image: none !important;
    }
    a, a.file-name
    {
        color:$primary!important;
        text-decoration: none;
        //font-weight: bold;
        &:hover
        {
            text-decoration:underline;
        }
    }
    td,th
    {
        padding:8px 12px;
        .ext
        {
            display: block;
            clear: both;
            font-size: 0.6rem;
            text-transform: uppercase;
        }
        form
        {
            margin-bottom: 0px!important;
        }
    }

    td.td-armagh, td.td-cathedral_quarter,  td.td-attractions, th.th-armagh, th.th-cathedral_quarter, th.th-attractions
    {
        border-left:1px dashed $black!important;
    }

    &.no-left
    {
        tr
        {
            vertical-align: top;
            td:first-child, th:first-child
            {
                padding-left:0px!important;
            }

        }
    }
    &.smaller-copy
    {
        thead, tbody
        {
            tr, td, th
            {
                font-size: 0.8rem!important;
            }
        }
    }
}

#reorderTable
{
    tbody
    {
        tr, td
        {
            cursor: move;
        }
    }
}
