
@font-face {
    font-family: 'Embrace-Giant';
    src: url('../font/Embrace-Giant.otf') format('opentype');
    src: url('../font/Embrace-Giant.woff2') format('woff2');
    src: url('../font/Embrace-Giant.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../font/Proxima-Nova-Light.woff') format('woff');
    src: url('../font/Proxima-Nova-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../font/Proxima-Nova-Light-It.woff') format('woff');
    src: url ('../font/Proxima-Nova-Light-It.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../font/Proxima-Nova-Regular.woff') format('woff');
    src: url ('../font/Proxima-Nova-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../font/Proxima-Nova-Regular-It.woff') format('woff');
    src: url ('../font/Proxima-Nova-Regualr-It.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../font/Proxima-Nova-Semibold.woff') format('woff');
    src: url ('../font/Proxima-Nova-Semibold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../font/Proxima-Nova-Bold.woff') format('woff');
    src: url ('../font/Proxima-Nova-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../font/Proxima-Nova-Black.woff') format('woff');
    src: url ('../font/Proxima-Nova-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}