footer
{
    position: absolute;
    bottom:0px;
    height: $footer_height;
    display: block;
    //background: blue;
    width: 100%; 
    z-index: 21;
    text-align: center;
    padding: 1rem;
    img
    {
        height: 75%;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        //width:auto;
        padding:0;
    }

    .direction-buttons {
        min-height: 41px;
        .button-and-message {
            margin-top: 0;
        }
    }
    .accessible-button-container {
        margin-top: 1.6rem;
        .accessible-button {
            &:not(:first-child) {
                //margin-left: 0.7rem;
            }
        }
    }
}

#browse-main, #pageRegion  {
    footer
    {
        text-align: left;
        padding: 0 3rem;
    }
}