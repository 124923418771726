body[data-theme-id="area-titanic-quarter"] {
	div.width-wrapper {
		background-color: $titanic_yellow;
	}

	#pageArea {
		header
		{
			.area-1
			{
				div.backdrop-1
				{
					opacity: 1;
					width: 100%;
					margin-top: -10%;
					height: auto;
					position: absolute;
				}
				div.backdrop-2
				{

				}

				div.pattern
				{
					background-color: white;
					background-size: 80%;
					opacity: 0.2;
				}

				div.featured
				{
				}
			}
		}
		main
		{
			.area-3
			{
				.slideshow-area
				{
					background: $titanic_yellow;
				}
			}
		}
	}
}