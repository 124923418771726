#locationNav
{
	position: relative;
	z-index: 1;
	margin: -$location_nav_height 0px 0px 0px;
	width: 100%;
	background: $graphite;
	height: $location_nav_height;
	padding: 0;


	#mapAreaNameButtonContainer {
		position: absolute;
		bottom:0;
		left:0;
		pointer-events: none;
		.inner-relative {
			pointer-events: none;
		}
		a.featured-button
		{
			pointer-events: auto;
			font-family: $eags_font;
			font-weight: normal;
			color: $map_green;
			transform: translate(-50%, -50%);
			text-align: center;
			font-size: 2rem;
			text-decoration: none;

			&:hover, &.selected, &.hovered-by-map-area, &.pressed-by-map-area {
				color: $white;
			}

		}
	}

	//display: none;
	opacity: 0;
	transition: opacity 0.5s;

	&.active
	{
		//display: block;
		opacity:1;
		z-index: 7;
	}
	img
	{
		position: absolute;
		bottom:0;
		left:0;
		width: 84%;
		height: auto;
	}
}