button, .button, .btn, input[type="button"], input[type="submit"]
{
    display: inline-block;
    font-size: 1.3rem;
    background: $button_grey;
    color: $white;
    padding: 0.5rem 3rem;
    &.back-button, &.next-button {
        padding-left: 0;
        padding-right: .9rem;
        width: 120px;
    }
    border:1px solid $button_grey;
    border-radius: 20px;
    text-transform: none;
    line-height: 1;
    text-decoration: none;
    transition: .2s ease-in-out;

    &.no-transition {
      -webkit-transition: none !important;
      -moz-transition: none !important;
      -o-transition: none !important;
      transition: none !important;
    }
    &.active, &:focus, &:focus-within
    {
        background: $white;
        color: $button_grey;
    }

}

.simple-button {
    cursor: pointer;
}

.next-button {
    text-align: center;
}
.back-button
{   
    padding:0.2rem 0 0.2rem;
    font-weight: $font_weight_semibold;
    vertical-align: middle;
    line-height: 1.5;

    &:before
    {
        display: block;
        float: left;
        font-family: $icons;
        content: ' \F12F';
        margin-left: 0.9rem;
        margin-right: 0.5rem;
        font-size: 2rem;
        line-height: 1;
    }
}

.button
{

    &.inverted
    {
        background: $white;
        color: $button_grey;
        border:1px solid $white;
        &.active, &:focus, &:focus-within
        {
            background: $button_grey;
            color: $white;
        }
    }

}

.button-and-message
{
    span
    {
        margin-left: 1rem;
        font-weight: $font_weight_bold;
        padding-top: 1rem;
        display: inline-block; 
        line-height:1;
        &:not(:only-child) {
            max-width: 55%;
        }
    }
    &.lg
    {
        span
        {
            font-size: 1.4rem;
        }
    }
    &.white
    {
        color:white;
    }
    margin-top: 2rem;
    &:not(:last-child) {
        margin-bottom: 2rem;
    }
}

a.button-with-message
{
    &:not(.no-mt) {
        margin-top: 2rem;
    }
    &:not(:last-child):not(.no-mb) {
        margin-bottom: 2rem;
    }
    color: $text_grey;
    text-decoration: none;
    display: block;
    &.active, &:focus, &:focus-within
    {
        &:not(.on-white) {
            color: $white;
        }
        .button
        {
            background: $white;
            color: $button_grey;
        }
    }
    .msg
    {
        margin-left: 1rem;
        font-weight: $font_weight_bold;
        padding-top: 1rem;
        display: inline-block;
        line-height:1;
        max-width: 55%;
    }
    .next-titles {
        margin-left: 1rem;
        height: 100%;
        .inner-bottom {
            position: absolute;
            bottom: 0;
        }
        #next-section-title, #next-product-title {
            display: block;
            font-weight: $font_weight_bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 280px;
        }


        @each $section_id,$colour in $section_colours
        {
            #next-section-title[data-section-id="#{$section_id}"]  {

                color: $colour;
            }
        }
    }
    &.lg
    {
        .msg
        {
            font-size: 1.4rem;
        }
    }
    &.white
    {
        color:white;
    }
}

a.accessible-button {
    width: 4.8rem;
    display: inline-block;
    float: left;
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
    &.is-click-action
    {
        cursor: pointer;
    }
    @each $region_area_id,$colour in $region_area_colours
    {
        &.#{$region_area_id} {
            .button-circle {

                @if $region_area_id == 'rainbow_quarter'
                {
                    background: rgb(120,0,141);
                    background: linear-gradient(53deg, rgba(120,0,141,1) 17%, rgba(40,88,247,1) 33%, rgba(38,136,48,1) 49%, rgba(249,233,42,1) 65%, rgba(244,143,1,1) 82%, rgba(219,0,0,1) 100%);
                }
                @if $region_area_id != 'rainbow_quarter'
                {
                    background-color: $colour;
                }
            }
            &.active, &:focus, &:focus-within, &.external-hover
            {
                .button-circle {
                    background: $white;
                    .button-circle-text {
                        color: $colour;
                    }
                }
                color: $colour;
            }
        }
    }
    .button-circle {
        display: inline-block;
        position: relative;
        width: 90%;
        aspect-ratio : 1;
        border-radius: 50%;

        .button-circle-text {
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 51%;
            font-weight: 500;
            font-size: 1.8rem;
            color: $white;
            line-height: 1;
        }
    }
    .button-text {
        display: inline-block;
        font-weight: 600;
        font-size: 1.0rem;
        color: $button_grey;
        width: 100%;
        line-height: 1.1;
        margin-top: 0.6rem;

    }
    &.light-text {
        .button-text {
            color: $white;
        }
    }
}


.button, .btn, button, input[type="submit"]
{
    &.midi
    {
        font-size: 0.8rem!important;
        color:white!important;
        text-decoration: none!important;
        &:hover
        {
            color: $button_grey!important;
        }
        border-radius:3px!important;
        margin:0px!important;
    }

    &.dni
    {
        background: $button_green;
        font-family: $eags_font;
        color: white;
        font-size: 1.6rem!important;
        padding:15px 20px!important;
        line-height: 1.2;
        &:hover
        {
            background: white;
            color:$button_green;
        }
    }
}

a[data-original-href] {
    cursor: pointer;
}