body[data-theme-id="browse"]  {
	div.width-wrapper {
		background-color: $browse_purple;
	}
	#pageBrowse {



		main{
			//margin-top: -100%;
			height: 100%;
			background: 0 0;

			.featured {
				height: 100%;

				.inner-relative {
					.welcome-text-cover, .touch-to-start-cover {
						width: 0;
						height: 100%;
						position: absolute;
						top: 0;
						right: 0;
						background-color: $browse_purple;
						pointer-events: none;
					}

					.welcome-text-container.lg, .touch-to-start-text-container {
						white-space: nowrap;
						span {
							font-size: 2.4rem;
							font-weight: 500;
							line-height: 1.2;
						}
					}
					.touch-to-start-text-container {
						color: #632b96;
					}
					.button-container {
						top: 43%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 100%;
						aspect-ratio : 1 / 1;
						position: relative;
						//background-color: red;

						.featured-button {
							transform: translate(-50%, -50%);
							top: 50%;
							> div {
								overflow: visible;
							}
							>.inner {
								position: relative;
								overflow: visible;
								width: 100%;
								height: 100%;
								.bg {
									content: ' ';
									position: absolute;
									display: inline;
									transform: translate(-50%, -50%);
									left: 50%;
									width:100%;
									height: 130%;
									top:60%;
									background: transparent;
								}
								.img-mask {
									height: 100%;
									width:100%;
									border-radius: 50%;
									overflow: hidden;
									position: absolute;
									transform: translate(-50%, -50%);
									left: 50%;
									top:50%;
								}
								img {
									transform: translate(-50%, -50%);
									top: 50%;
									left: 50%;
									//height: 85%;
									height: 100%;
									width: auto;
									max-width: unset;
									max-height: unset;
								}
								.button-cover {
									width: 0;
									height: 110%;
									position: absolute;
									top: -5%;
									right: 0;
									background-color: $browse_purple;
									pointer-events: none;
								}
								.button {
									transform: translate(-50%, -50%);
									top: 50%;
									left: 50%;
								}
							}

							&.faq {
								left: 50%;
								top: 118%;
								height: 11%;
								aspect-ratio: 1 / 1;

								>.inner {
									.bg {
										width:200%;
										left: 54%;
									}
									.img-mask {
										left: 53%;
										border-radius: 0;
										transform: translate(-50%, 0);
										bottom: 0;
										top: unset;
									}
									img {
										top: unset;
										bottom: 0;
										position: absolute;
										transform: translate(-50%, 0);
									}
									.button {
										top: 130%;
										left: 50%;
										max-width: unset;
										width: unset;
										white-space: nowrap;
									}
								}
							}
							&.area-button
							{
								height: 44%;
								//height: 52%;
								aspect-ratio: 1 / 1;

								>.inner{
									.button {
										top: 116%;
										left: 50%;
									}
									.hand-pulse {
										top: 128%;
										height: 52%;
										width: 34%;
										//background-color: green;
										position: absolute;
										transform: translate(-50%, 0);
										left: 50%;
										>.inner {
											position: relative;
											width: 100%;
											height: 100%;
											.rings {
												position: absolute;
												width: 100%;
												aspect-ratio: 1 / 1;
												top: 0;
												left: 0;
												//transform: translate(-50%, -50%);
												.ring {
													aspect-ratio: 1 / 1;
													border-radius: 50%;
													transform: translate(-50%, -50%);
													left: 50%;
													top:50%;
													position: absolute;

													&:nth-child(1) {
														width: 100%;
														opacity: 0.24;
													}
													&:nth-child(2) {
														width: 76%;
													}
													&:nth-child(3) {
														width: 62%;
														opacity: 0.58;
													}
													&:nth-child(4) {
														width: 40%;
													}
													&:nth-child(5) {
														width: 28%;
													}

													&:not(.cover-ring) {
														background-color: $browse_light_purple;
													}
													&.cover-ring {
														background-color: $browse_purple;
													}
												}
											}
											.hand {
												position: absolute;
												width: 84%;
												height: 70%;
												bottom: 0;
												right: 4%;

												>.inner {
													position: relative;
													width: 100%;
													height: 100%;
													.hand-img {
														position: absolute;
														transform: translate(-50%, 0);
														bottom: 0;
														left: 50%;
														width: 100%;
														height: 100%;
														background-image: url($media_path + 'custom-icons/hand.png');
														background-position: center bottom;
														background-size: 100% auto;
														background-repeat: no-repeat;
														&.hand-bg {
															width: 110%;
															height: 86%;
															background-image: url($media_path + 'custom-icons/hand-dark.png');
														}

													}
												}
											}
										}
									}
								}
								&.is-hovered, &.external-hover {

									.hand-pulse {

										> .inner {

											.rings {
												.ring {

													&:not(.cover-ring) {
														background-color: $white;
													}
												}
											}
										}
									}
								}

								&.belfast {
									left: 28%;
									.bg {
										width:110%;
										left: 64%;
									}
									.img-mask {
										left: 65%;
										border-radius: 50%;
									}
								}

								&.ni {
									left: 75%;
									.bg {
										width:60%;
										left: 54%;
									}
									.img-mask {
										left: 53%;
										border-radius: 0;
										transform: translate(-50%, 0);
										top:0;
									}
									img {
										transform: translate(-50%, 0);
										top:0;
									}
								}
							}
						}

					}
				}
			}
		}
        //background-color: orange;
        //margin-top: 200px;
        //min-height: 500px
    }
}