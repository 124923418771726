
#slideshowCarousel {
    background: $background_grey;
    .carousel-inner {
        .carousel-item {

            .image-title-container {
                position: absolute;
                top:auto;
                left:auto;
                right: 0px;
                bottom:0px;
                .image-title 
                {
                    padding-right: 12px;
                    padding-bottom: 12px;
                    color: white;
                    text-shadow: 2px 2px 5px black;
                    text-align: right;
                }
            }
            &:not(.active) {
                .image-label {
                    opacity: 0;
                }
            }
        }
    }
}