body[data-theme-id="area-north-belfast"] {
	div.width-wrapper {
		background-color: $north_green;
	}

	#pageArea {
		header
		{
			.area-1
			{
				div.backdrop-1
				{
					width:100%;
					height: auto;
					top:auto;
					bottom:0px;
					margin:0px;
				}
				div.backdrop-2
				{
				}

				div.pattern
				{
					background-color: $north_green;
					background-size: 100%;
					opacity: 0.8;
				}
			}
		}
		main
		{
			.area-3
			{
				.slideshow-area
				{
					background: $north_green;
				}
			}
		}
	}
}