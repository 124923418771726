.modal {
    --bs-modal-width: calc(1080px - 6rem);
    --bs-modal-bg: rgba(255, 255, 255, .9);
    --bs-modal-margin: 3rem;
    backface-visibility: hidden;
    &.fade
    {
        .modal-dialog
        {
            transition: all 0.3s;
        }

        &:not(.show)
        {
            .modal-dialog
            {
                transform-style: preserve-3d;
                transform: rotateX(-70deg);
                opacity: 0;
            }
        }
        &.show
        {
            &.internal-transition
            {
                .modal-dialog
                {
                    transform-style: preserve-3d;
                    transform: rotateY(-70deg);
                    opacity: 0;
                }
            }
        }
    }
    .modal-dialog{
        transform-origin: 50% 50%;
    }

    .modal-content{
        height: calc(1920px - 6rem);
        position: relative;
        background: $titanic_grey;
        border-radius:0;
        >div{
            height: 100%;
        }
    }
    .faq-modal 
    {
        h1
        {
            font-size: 5.0rem;
            color: $graphite;
        }

        h2
        {
            font-size: 2.4rem;
            margin-top: 3.2rem;
            color:$titanic_orange;
        }

        #modalHeader
        {
            padding: 2rem;
            background-image: url($media_path + 'faq/faq-header-texture.png');
            background-size:cover;
            height: 26%;

            &.extended
            {
                padding-bottom: 20rem;
            }

            position:relative;
            .button-holder
            {
                position: absolute;
                bottom: 0;
                left: 0;
                padding-left: 2rem;
                padding-bottom: 2rem;
            }


        }
        #modalMain
        {
            padding: 2rem;
            h3{
                color: $titanic_orange;
                font-weight: 500;
            }
            p, ul{
                li{
                    line-height: 1.3;
                    font-weight: 300;
                    font-size: 1.1rem;
                }
                margin: 2rem 0;
            }
            p, ul {
                li {
                    line-height: 1.8;
                }
            }
            .faq-tile-group
            {
                .faq-tile-group-header
                {
                    //margin: 1rem auto;
                }
                .faq-tile-group-row
                {

                    .col
                    {
                        
                    }
                    .tile-btn 
                    {

                        color:$graphite;
                        font-size: 1.0rem;
                        line-height: 1.25;
                        border: 3px solid $white;
                        padding: 1.2rem 1.2rem;
                        background: transparent;
                        transition: .2s ease-in-out;
                        border-radius: 10px;
                        min-height: 124px;

                        &.active, &:focus, &:focus-within, &:hover, &.selected
                        {
                            background-color: white;

                            color: $graphite;
                            cursor:pointer;
                        }
                    }
                }
            }

            .faq-title
            {
                text-align: left;
                margin-top: 4.8rem;
                h3
                {
                    font-weight: bold;
                    text-align: left;
                    font-size: 2.8rem;
                }
            }
        }
        #modalFooter
        {
            padding: 2rem;
            position: absolute;
            height: 12%;
            bottom: 0;
        }
    }
}