h1, h2, h3, h4, h5, h6, p, div, span, th, td, li
{
	line-height: 1;
}

h1, h2, h3, h4, h5
{
	font-family: $title_font;
}

h1
{
	color: $white;
	font-size: 6rem;
	font-weight: $font_weight_boldest;
	&.reduced
	{
		font-size: 4.5rem;
	}
}

h2
{
	font-size: 3.6rem;
	line-height: 1;
	font-weight: $font_weight_light;
	&.reduced
	{
		font-size: 2.5rem;
	}
	margin-bottom:1rem;

}

p, span, div.wysiwyg-content
{
	font-size: 1.0rem;
	line-height: 1.3;
	font-weight: $font_weight_light;
}