body[data-theme-id="area-queens-quarter"] {
	div.width-wrapper {
		background-color: $queens_green;
	}

	#pageArea {
		header
		{
			.area-1
			{
				div.backdrop-1
				{
					width: 95%;
					opacity: 0.8;
					position: absolute;
					img
					{
						width: 100%;
						height: auto;
						opacity: 1;
					}
				}
				div.backdrop-2
				{
					width: 95%;
					opacity: 1;
					position: absolute;
					img
					{
						width: 95%;
						height: auto;
						opacity: 1;
					}

				}


				div.featured
				{
				}
			}
		}
		main
		{
			.area-3
			{
				.slideshow-area
				{
					background: $queens_green;
				}
			}
		}
	}
}