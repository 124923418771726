#mainNav
{
	position: relative;
	z-index: 8;
	margin: -$main_nav_height $area_margin 0px $area_margin;
	width: calc(100% - (2 * #{$area_margin})  );  //100%;
	height:$main_nav_height;
	ul
	{
		list-style-type: none;
		margin: 0px;
		padding: 0px;
		li
		{
			display: block;
			float: left;
			margin: 0px 5px 0px 0px;
			font-size: 15px;
			position: relative;
			&.no-section
			{
				a
				{
					cursor: pointer;
				}
			}
			&:last-of-type
			{
				margin-right:0px;
			}
			a
			{
				display: block;
				height: 100%;
				background: $red;
				padding: 0px 1.75rem 0px 1.75rem;
				text-align: center;	
				border:none;
				border-top-left-radius: 15px;
				border-top-right-radius: 15px;
				color:white;
				text-decoration: none;
				font-weight: $font_weight_semibold;
				transition: .2s ease-in-out;
				max-width: 180px;
				vertical-align: middle;
			}

            @each $section_id,$colour in $section_colours
            {

				a[data-section-id="#{$section_id}"]  {

					background: rgba($colour, 0.9);
                	&.active, &:focus, &:focus-within, &:hover, &.selected
                	{
                		background: rgba($colour, 1);
						border-top-left-radius: 15px;
						border-top-right-radius: 15px;
                	}
				}
			}

            &.nav-image
            {
            	float:right;
            	margin-right: 0px;
            	&:last-of-type
            	{
            		margin-right:5px;
            	}
            	a
            	{
            		//padding: calc( #{$main_nav_height} * 0.2 ) 1.5rem calc( #{$main_nav_height} * 0.2)  1.5rem;
            	}
				img
				{
					height: 60%;
					width:auto;
					position: absolute;
					top:50%;
					left:50%;
					transform: translate(-50%, -50%);
				}

            }


            &.information, &.location
            {
            	a
            	{
            		background: rgba($white, 0.8);
            	}
            	&.active, &:focus, &:focus-within, &:hover, &.selected
            	{
            		background: rgba($white, 1);
					border-top-left-radius: 15px;
					border-top-right-radius: 15px;
            	}

            }


			&.faq, &.faq
			{
				a
				{
					background: rgba($titanic_grey_blue, 0.9);
				}
				&.active, &:focus, &:focus-within, &:hover, &.selected
				{
					background: rgba($titanic_grey_blue, 1);
					border-top-left-radius: 15px;
					border-top-right-radius: 15px;
				}

			}

		}


	}
}