.horizontal-list
{
    list-style-type: none;
    margin:0px;
    padding:0px;
    li
    {
    	display: inline-block;
    	margin:0px 5px 5px 0px;
    	&:last-of-type
    	{
    		margin:0px;
    	}
        a
        {
            text-decoration: none;
        }
        a.current
        {
            text-decoration: underline;
        }
    }

    &.with-pipe
    {
        li
        {
            margin:0px;
            &:after
            {
                display:inline-block;
                content:"|";
                padding:0px 5px;
            }
            &:last-of-type
            {
                &:after
                {
                    content: "";
                }
            }
        }
    }
}

.icon-and-message
{
    list-style-type: none;
    margin:2rem 0px;
    padding:0px;
    li
    {
        display: inline-block;
        margin:0px 1rem 2.6rem 0rem;
        padding-left: 3.2rem;
        position: relative;
        &:before
        {    
            background-color:$grey_dark;
            display: inline-block;
            content: ' ';
            border:2px solid $grey_dark;
            border-radius: 50%;
            width:2.6rem;
            height:2.6rem;
            background-size: 1.6rem 1.6rem;
            background-position: center center;
            background-repeat: no-repeat;
            padding:0px;
            margin: 0px;
            position: absolute;
            left: 0px;
            top:-0.8rem;

        }
        &.translink 
        {
            width: 100%;
            clear:both;
            &:before 
            {
                background-color:unset;
                border: unset;
                background-size: 2.6rem 2.6rem;
                
            }

        }

        @each $ic in $custom_icons
        {
            &.#{$ic} 
            {
                &:before
                {
                    background-image: url($media_path + "custom-icons/" + $ic + ".svg");                    
                }
            }
        }

    }

}

.control-icons
{
    list-style-type: none;
    margin:0px;
    padding:0px;
    z-index: 99;
    li
    {
        display: inline-block;
        margin:0px 1rem 1rem 0rem;
        font-size: 2.6rem;
        &:last-of-type
        {
            margin-right:0px;
        }
        i
        {
            padding: 0px;
        }
        a
        {
            //color:$button_grey;
            .svg-icon {
                width: 42px;
                height: 42px;


                @each $ic in $dark_disabled_custom_icons
                {
                    &.#{$ic}
                    {
                        background-image: url($media_path + "custom-icons/" + $ic + "-light.svg");
                    }
                }
                &.dark {
                    @each $ic in $dark_disabled_custom_icons
                    {
                        &.#{$ic}
                        {
                            background-image: url($media_path + "custom-icons/" + $ic + "-dark.svg");
                        }
                    }
                }
            }

            i
            {
                fill: $white;
                &:before
                {
                    fill: $white;
                    background: $white;
                    -webkit-box-shadow:inset 0 0 0 1px $button_grey;
                    -moz-box-shadow:inset 0 0 0 1px $button_grey;
                    box-shadow:inset 0 0 0 1px $button_grey;
                }
                transition: .2s ease-in-out;
            }
            i.m40-rounded
            {
                &:before
                {
                    border-radius: 50%;
                }
            }
            &.active, &:focus, &:focus-within, &:hover
            {
                i
                {
                    color:$white;
                    fill: $button_grey;
                    &:before
                    {
                        fill: $button_grey;
                        background: $button_grey;
                        -webkit-box-shadow:inset 0 0 0 1px $white;
                        -moz-box-shadow:inset 0 0 0 1px $white;
                        box-shadow:inset 0 0 0 1px $white;
                    }
                }
            }

            &.disabled {
                //opacity: 0.5;
                pointer-events: none;
                .svg-icon, .svg-icon.dark {


                    @each $ic in $dark_disabled_custom_icons
                    {
                        &.#{$ic}
                        {
                            background-image: url($media_path + "custom-icons/" + $ic + "-disabled.svg");
                        }
                    }
                }
            }
            
        }
    }
}