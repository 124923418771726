body[data-theme-id="region-belfast"]  {
	div.width-wrapper {
		background-color: $belfast_yellow;
	}

	#pageRegion {

		header {
			position: relative;
		}

		.region-background-container {
			position: absolute;
			height: 100%;
			overflow: hidden;
			right: 0;
		}

		img.starburst
		{
			margin-top: -233px;
		}

		.welcome-text-container {
			overflow: hidden;
			span {
				white-space: nowrap;
			}
		}

		.on-lagan
		{
			margin-top: -864px;
			height: 100%;
			.featured
			{
				height: 100%;
				.inner-relative
				{

					.button-container {
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 100%;
						aspect-ratio: 1 / 1;
						position: relative;
						//background-color: red;
						.featured-button
						{
							aspect-ratio : 1 / 1;
							transform: translate(-50%, -50%);
							.inner{
								position: relative;
								overflow: visible;
								width: 100%;
								height: 100%;
								&.circle-large
								{
									width:42%;
								}
								.bg {
									content: ' ';
									position: absolute;
									display: inline;
									transform: translate(-50%, -50%);
									left: 50%;
									top:50%;
									width:100%;
									height: 100%;
									background: transparent;
									border-radius: 50%;
								}
								.img-mask {
									height: 100%;
									width:100%;
									border-radius: 50%;
									//overflow: hidden;
									position: absolute;
									transform: translate(-50%, -50%);
									left: 50%;
									top:50%;
								}
								img {
									transform: translate(-50%, -50%);
									top: 50%;
									left: 50%;
								}
								.button {
									transform: translate(-50%, -50%);
									left: 50%;
									top: 50%;
								}
							}
							&.north-belfast
							{
								left: 38%;
								top: 17%;
								width: 34%;
								.inner {
									.bg {
										background: $north_green;
									}
									img {
										width: 123%;
										left: 41%;
										top: 40%;
									}
									.button {
										left: 72%;
										top: 76%;
									}
								}
							}
							&.titanic-quarter
							{
								left: 89%;
								top: 17%;
								width:38%;
								.inner {
									.bg {
										background: $titanic_yellow;
									}
									img {
										height: 95%;
										width: auto;
										left: 42%;
										top: 32%;
									}
									.button {
										top: 80%;
										left: 42%;
									}
								}
							}
							&.shankill-quarter
							{
								left: 8%;
								top: 32%;
								width: 28%;
								.inner {
									.bg {
										background: $shankill_blue;
									}
									img {
										height: 63%;
										width: auto;
										top: 54%;
										left: 62%;
									}
									.button {
										top: 52%;
										left: 106%;
									}
								}
							}
							&.cathedral-quarter
							{
								left: 67%;
								top: 45%;
								width: 31%;
								.inner {
									.bg {
										background: $cathedral_red;
									}
									img {
										width: 94%;
										left: 50%;
										top: 40%;
									}
									.button {
										left: 80%;
										top: 53%;
									}
								}
							}
							&.rainbow-quarter
							{
								left: 34%;
								top: 53%;
								width: 23%;
								.inner {
									.bg {
										background: transparent!important;
									}
									img {
										width: 100%;
										left: 50%;
										top: 50%;
									}
									.button {
										top: 18%;
									}
								}
							}
							&.gaeltacht-quarter
							{
								left: 9%;
								top: 65%;
								width:36%;
								.inner {
									.bg {
										background: $gaeltacht_green;
									}
									img {
										height: 74%;
										width: auto;
										left: 63%;
										top: 32%;
									}
									.button {
										top: 56%;
										left: 82%;
									}
								}
							}
							&.city-centre
							{
								left: 50%;
								top: 71%;
								width: 33%;
								.inner {
									.bg {
										background: $city_blue;
									}
									img {
										height: 113%;
										width: auto;
										left: 50%;
										top: 30%;
									}
									.button {
										top: 50%;
									}
								}
							}
							&.east-side
							{
								left: 94%;
								top: 76%;
								width: 30%;
								.inner {
									.bg {
										background: $eastside_orange;
									}
									img {
										height: 116%;
										width: auto;
										left: 52%;
										top: 30%;
									}
									.button {
										left: 20%;
										top: 40%;
									}
								}
							}
							&.queens-quarter
							{
								left: 66%;
								top: 104%;
								width: 32%;
								.inner {
									.bg {
										background: $queens_green;
									}
									img {
										width: 123%;
										left: 30%;
										top: 40%;
									}
									.button {
										top: 62%;
										left: 40%;
									}
								}
							}
							&.linen-quarter
							{
								left: 21%;
								top: 89%;
								width: 29%;
								.inner {
									.bg {
										background: $linen_purple;
									}
									img {
										height: 89%;
										width: auto;
										left: 30%;
										top: 42%;
									}
									.button {
										left: 80%;
										top: 46%;
									}
								}
							}
						}
					}
					div.featured-image
					{
						&.fish
						{
							overflow: hidden;
						}
					}





				}
			}

		}

	}
}