main
{
    background: white;
    width: 100%;
    //height: 55vh; // 960px
    //min-height:1056px;
    height: 1056px;
    position: relative;
    z-index: 20; // arbitrary

    color: $text_grey;


    .area-2
    {
        background: white;
        .area-inner {
            padding: $area_margin;
            height: calc( 100% - #{$footer_height});
            .area-relative {
                width: 100%;
                height: 100%;
                position: relative;
            }
        }
    }
    .area-3
    {
        background: $background_grey;

        .area-inner {
            height: calc( 100% - #{$footer_height});
            .area-relative {
                width: 100%;
                height: 100%;
                position: relative;
            }
        }

        .slideshow-area
        {
            padding:$area_margin 0 0 0;
            position: relative;
            background: white;
            ul.control-icons
            {
                position: absolute;
                top: calc( #{$area_margin} - 1.3rem );
                right: $area_margin;

            }

            &.has-logo-area
            {
                margin-bottom: 140px;
                min-height: 150px;
            }


            .logo-area
            {
                position: absolute;
                bottom:-110px;
                right: $area_margin;
                height:220px;
                .logo
                {
                    height: 100%;
                    display: inline-block;
                    margin-left: 2rem;
                    &.eags{
                        background: white;
                        border-radius: 20px;
                        padding: 1rem;
                    }
                    img
                    {
                        height: 100%;
                        width: auto;
                    }
                }
            }

        }



    }
    section.admin-section
    {
        padding: 2rem 0;
        border-bottom: 1px solid $grey;
        h2
        {
            font-size: 2.4rem;
        }
    }
    .area-cover {
        width: 100%;;
        height: 100%;
        background-color: $white;
        position: absolute;
        top: 0;
        pointer-events: none;
        opacity: 0;
    }

}

.qr-area
{
    //position: absolute;
    //bottom: $area_margin;
    //right: $area_margin;
    text-align: right;
    margin: 1.5rem $area_margin;
    .qr-code
    {
        width: 130px;
        height: 130px;
        padding: 10px;
        background: white;
        border-radius: 5px;
        margin-left: 1rem;
        margin-top: 0.3rem;
        display: inline-block;
        &.active, &:focus, &:focus-within, &:hover
        {
            zoom:1.1;
        }
    }
    .qr-info
    {
        font-size: 1.2rem;
        display: inline-block;
    }
}

.admin-page {
    .width-wrapper {
        height: unset;
    }
    main
    {
        height: unset;
    }
}

html.flow
{
    main
    {
        height: 100%!important;       
        padding-top: 2rem;
        padding-bottom: 2rem;
        overflow: auto; 
    }
}

.interactions-disabled {
    pointer-events: none;
}

#imagePreloader {
    display: none;
    .image-preloader-inner {
        position: relative;
        .preloaded-image {
            position: absolute;
        }
    }
}

.content-is-loading {
    opacity: 0;
}