#supportingNav
{
	position: relative;
	z-index: 1;
	margin: -$supporting_nav_height 0px 0px 0px;
	width: 100%;
	background: $graphite;
	height: $supporting_nav_height;
	padding: 1rem $area_margin;

	//display: none;
	opacity: 0;
	transition: opacity 0.5s;
	$item_side_padding: 1.5rem;
	&.active
	{
		//display: block;
		opacity:1;
		z-index: 7;
	}
	h2
	{
		font-size: 2.2rem;
		margin-bottom: 1.1rem;
		color: $white;
	}
	.inner-nav {
		display: inline-block;
		width: 100%;
		.carousel-title {
			float: left;
		}
		.controllers
		{
			float: right;
		}
	}

	#nav-carousel-container {
		display: inline-block;
		width: 100%;
	}

	#supportingNavLogo
	{
		width:150px;
		height: auto;
		float: left;
	}
	#nav-carousel {
		width: calc(100% + #{$item_side_padding});
		margin-right: -$item_side_padding;
		height: 150px;
		float: right;
		overflow: hidden;
	}

	&:not(.with-nav-logo) {
		#supportingNavLogo
		{
			display: none;
		}
	}

	.carousel-item-container
	{
		height: 100%;
	}

	&.with-nav-logo
	{

		h2
		{
			color:$graphite;
		}
		#nav-carousel
		{
			width: calc(100% - 200px);
		}
	}

	.item
	{
		float: left;
		//width: calc(100% / 6);
		padding: 0 $item_side_padding 1rem 0;
		a
		{
			text-decoration: none;
		}
		img
		{
			margin-bottom: 0.5rem;
			&:hover
			{
				border:3px solid $red;
			}
		}
		span
		{
			display: block;
			color: $white;
			text-decoration: none;
		}
		&.currently-showing
		{
			img
			{
				border:3px solid $orange;
			}
		}
	}
}