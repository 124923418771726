body[data-theme-id="area-city-centre"] {
	div.width-wrapper {
		background-color: $city_blue;
	}

	#pageArea {
		header
		{
			.area-1
			{
				div.backdrop-1
				{

					position: absolute;
					width: 80%;
					height: 80%;
					bottom: 0px;
					left: 60%;
					overflow: hidden;
					z-index: 2;
					transform: translateX(-50%);
					img
					{
						display: block;
						height:100%;
						height: auto;
						overflow: hidden;
					}

				}
				div.backdrop-2
				{
				}

				div.pattern
				{
					background-color: $city_blue;
					background-size: 100%;
					opacity: 0.5;
				}

				div.featured
				{
				}
			}
		}
		main
		{
			.area-3
			{
				.slideshow-area
				{
					background: $city_blue;
				}
			}
		}
	}
}