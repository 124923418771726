body[data-theme-id="area-linen-quarter"] {
	div.width-wrapper {
		background-color: $linen_purple;
	}

	#pageArea {
		header
		{
			.area-1
			{
				div.backdrop-1
				{
					opacity: 1;
					width: 100%;
					//margin-top: -10%;
					height: auto;
					position: absolute;
				}

				div.backdrop-2
				{

				}

				div.pattern
				{
					background-size: 100%;
				}

				div.featured
				{
				}
			}
		}
		main
		{
			.area-3
			{
				.slideshow-area
				{
					background: $linen_purple;
				}
			}
		}

		img#linen_empty
		{
			width:1080px;
			height: 630px;
			position: absolute;
			left:0px;
			bottom:64px;
			z-index: 15;
		}
	}
}